// 1. import `extendTheme` function
import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// 3. extend the theme
const theme = extendTheme({
  config,
  colors: {
    brand: {
      "50": "#ECF0F8",
      "100": "#CBD5EB",
      "200": "#AABADF",
      "300": "#88A0D2",
      "400": "#6785C6",
      "500": "#4264af",
      "600": "#385594",
      "700": "#2A3F6F",
      "800": "#1C2A4A",
      "900": "#0E1525",
      "1000": "#080D16",
      red: "#c3382d",
      green: "#3C8D50",
      blue: "#4264af",
      smurf: "#26A9E0",
      cement: "#d1d2d4",
      steel: "#6d6e71",
      orange: "#F6921E",
    },
    jty: {
      100: "#49AB62",
      200: "#3C8D50",
      300: "#378149",
      400: "#317241",
      500: "#2B6439",
      600: "#255631",
      700: "#3C8D50",
      800: "#193921",
      900: "#132B19",
    },
    econic: {
      "50": "#ECF0F8",
      "100": "#CBD5EB",
      "200": "#AABADF",
      "300": "#88A0D2",
      "400": "#6785C6",
      "500": "#4264af",
      "600": "#385594",
      "700": "#2A3F6F",
      "800": "#1C2A4A",
      "900": "#0E1525",
      "1000": "#070A11",
      red: "#c3382d",
      green: "#3C8D50",
      blue: "#4264af",
      smurf: "#26A9E0",
      orange: "#F6921E",
    },
    darkBg: "#0E1011",
    // lightbg:
  },
  shadows: {
    outline: "0 0 0 2px #4264af",
  },
  styles: {
    global: (props: any) => ({
      body: {
        // fontFamily: "ubuntu",
        color: mode("gray.800", "whiteAlpha.900")(props),
        bg: mode("#FFF", "brand.900")(props),
        lineHeight: "base",
      },
      "&::-webkit-scrollbar": {
        backgroundColor: mode("#EEF2F7", "#212324")(props),
      },
      "&:hover::-webkit-scrollbar-thumb": {
        backgroundColor: mode("#999", "#444")(props),
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: mode("#777 ", "#888")(props),
      },
    }),
  },
  components: {
    Button: {
      variants: {
        "with-shadow": {
          bg: "red.400",
          boxShadow: "0 0 2px 2px #efdfde",
        },
      },
    },
    Link: {
      variants: {
        // you can name it whatever you want
        primary: () => ({
          _hover: {
            color: `#4264af`,
          },
          _activeLink: {
            color: `#4264af`,
          },
        }),
      },
      defaultProps: {
        // you can name it whatever you want
        variant: "primary",
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: "#4264af",
      },
    },
    Tooltip: {
      baseStyle: (props: any) => ({
        bg: props.colorMode == "dark" ? "#292D33" : "#dedede",
        color: props.colorMode == "dark" ? "#FFF" : "#000",
      }),
    },
    Popover: {
      baseStyle: (props: any) => ({
        content: {
          backgroundColor: props.colorMode == "dark" ? "brand.900" : "#FFF",
          arrow: {
            background: props.colorMode == "dark" ? "brand.900" : "#FFF",
          },
        },
      }),
    },
  },
});

export default theme;
