import "../styles/globals.css";
import type { AppProps } from "next/app";
import { ChakraProvider, cookieStorageManagerSSR, Link} from "@chakra-ui/react";
import theme from "../theme";
import adze from "adze";
import { Chakra } from "../chakra";
import { RecoilRoot } from "recoil";
import Head from "next/head";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import "../responsive.css";

function MyApp({ Component, pageProps }: AppProps) {
  const manager = cookieStorageManagerSSR(pageProps.cookie);
  dayjs.extend(LocalizedFormat);
  const queryClient = new QueryClient();
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <Chakra cookies={pageProps.cookies}>
          <Head>
            <link rel="icon" href="logoOnlyDark.svg" type="image/svg+xml" />
            <title>Econic Network</title>
          </Head>
          <Component {...pageProps} />
        </Chakra>
       {/* <ReactQueryDevtools initialIsOpen={false} />*/}
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default MyApp;
